import { createRouter, createWebHistory,createWebHashHistory } from 'vue-router'
// import { loadMap } from './routes'
// import HelloWorld from '../components/HelloWorld.vue'

const routes = [
  {
    path: '/',
    redirect:'/home',
    component: () => import('../view/homeView.vue')
  },
    // children:[
      
    // ]
    {
      path: '/home',
      name: '首页',
      component: () => import('../view/homeView.vue')
    },
    // {
    //   path: '/about',
    //   name: 'about',
    //   component: () => import('../view/aboutView.vue')
    // },
    {
      path: '/datacore',
      name: '财务清分',
      component: () => import('../view/finance/datacore.vue')
    },
    {
      path: '/cashflow',
      name: '资金预处理',
      component: () => import('../view/finance/cashflow.vue')
    },
    {
      path: '/brm',
      name: '商业关系管理',
      component: () => import('../view/marketing/brm.vue')
    },
    {
      path: '/rbm',
      name: '商业关系',
      component: () => import('../view/marketing/rbm.vue')
    },
    {
      path: '/mdm',
      name: '主数据管理',
      component: () => import('../view/data/mdm.vue')
    },
    {
      path: '/measurement',
      name: '指标测算',
      component: () => import('../view/data/measurement.vue')
    },
    {
      path: '/project',
      name: '项目管理协同',
      component: () => import('../view/other/project.vue')
    },
    {
      path: '/topic',
      name: '调查问卷',
      component: () => import('../view/other/topic.vue')
    },
    {
      path: '/sparring',
      name: '陪练机器人',
      component: () => import('../view/other/sparring.vue')
    },
    {
      path: '/contract',
      name: '合同管理',
      component: () => import('../view/other/contract.vue')
    },
    {
      path: '/lms',
      name: '领导能力测评与训练',
      component: () => import('../view/other/lms.vue')
    }
 
]
const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
          return savedPosition
      } else {
          return { top: 0 }
      }
  },
  // @ts-ignore
  routes: routes,
})

// await loadMap(router, routes);
router.beforeEach((to,from,next) => {
  document.title="学信通咨询官网-"+to.name;
  next()
})

export default router