<template>
   <router-view></router-view>
</template>

<script setup>
</script>

<style>
body,#app {
  margin: 0px;
}
</style>
